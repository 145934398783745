<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="text-center"
              >Create Appointment Event</v-card-title
            >
            <v-card-text>
              <v-form @submit.prevent="createEvent">
                <v-text-field
                  v-model="newEvent.title"
                  label="Title"
                  required
                ></v-text-field>
                <v-textarea
                  v-model="newEvent.description"
                  label="Description"
                  required
                ></v-textarea>
                <v-text-field
                  v-model="newEvent.start_time"
                  label="Start Time"
                  type="datetime-local"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newEvent.end_time"
                  label="End Time"
                  type="datetime-local"
                  required
                ></v-text-field>
                <v-btn type="submit" color="primary">Create </v-btn>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title class="text-center">Appointment List</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item v-for="event in events" :key="event.id">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ event.title }} -
                      {{ event.start_time }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      :id="'showButton_' + event.id"
                      @click="showEvent(event.id)"
                    >
                      Show
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
  
  <script>
export default {
  data() {
    return {
      newEvent: {
        title: "",
        description: "",
        start_time: "",
        end_time: "",
      },
      events: [],
    };
  },
  mounted() {
    this.fetchEvents();
  },
  methods: {
    createEvent() {
      this.$api
        .post("/event/", this.newEvent)
        .then((response) => {
          this.newEvent = {
            title: "",
            description: "",
            start_time: "",
            end_time: "",
          };
          this.fetchEvents();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchEvents() {
      this.$api
        .get("/event/")
        .then((response) => {
          this.events = response.data.results;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
  
  <style>
/* CSS styles specific to this component */
</style>
  